<template>
	<div style="background-color: #222222;color:#dedede;height:100vh;">
		<div
			class="tsls_outer"
			style="text-align: center;"
			v-if="data && !scoreboardIsActive"
		>
			<div
				style="text-align: center;"
				class="tsls_scoreboard_name"
			>{{data.data.scoreboard}}</div>
			<div
				v-if="data.date_updated"
				style="text-align: center;"
				class="tsls_meta"
			>
				{{$date(data.date_updated).format('DD-MM-YYYY HH:mm:ss')}} | POWERED BY <B>TARGASCORE</B></div>

			<div class="tsls_player"><br><br>Momenteel geen live score</div>
		</div>
		<div
			class="tsls_outer"
			:style="!isFullScreen ? [{cursor:'pointer'}] : []"
			v-if="data && scoreboardIsActive"
			@click="tsls_openFullScreen()"
		>

			<table>
				<tr>
					<td colspan="3">
						<div
							style="text-align: center;"
							class="tsls_scoreboard_name"
						>{{data.data.scoreboard}}</div>
						<div
							v-if="data.date_updated"
							style="text-align: center;"
							class="tsls_meta"
						>
							{{$date(data.date_updated).format('DD-MM-YYYY HH:mm:ss')}} | POWERED BY <B>TARGASCORE</B></div>
					</td>
				</tr>
				<tr>
					<td>
						<div
							style="text-align: center;"
							class="tsls_player color1"
						>{{data.data.player1.info.name}} <span v-if="data.data.player1.info.car">({{data.data.player1.info.car}})</span></div>
					</td>
					<td style=""></td>
					<td>
						<div
							style="text-align: center;"
							class="tsls_player color2"
						>{{data.data.player2.info.name}} <span v-if="data.data.player2.info.car">({{data.data.player2.info.car}})</span></div>
					</td>
				</tr>
				<tr v-if="data.data.player1.info.discipline && data.data.player2.info.discipline">
					<td>
						<div
							style="text-align: center;"
							class="tsls_discipline color1"
						>{{data.data.player1.info.discipline}} </div>
					</td>
					<td style=""></td>
					<td>
						<div
							style="text-align: center;"
							class="tsls_discipline color2"
						>{{data.data.player2.info.discipline}} </div>
					</td>
				</tr>
				<tr>
					<td>
						<div
							style="text-align: center;"
							class="tsls_car color1"
						>{{data.data.player1.car}}</div>
					</td>
					<td style="">
						<div
							style="text-align: center;"
							class="tsls_innings"
						>{{data.data.innings}}</div>
						<div
							style="text-align: center;"
							class="tsls_meta"
						>BRT</div>
					</td>
					<td>
						<div
							style="text-align: center;"
							class="tsls_car color2"
						>{{data.data.player2.car}}</div>
					</td>
				</tr>
				<tr>
					<td style="text-align:center;">
						<div>
							<div style="display:inline-block;">
								<div
									style="text-align: center;"
									class="tsls_highrun color1"
								>{{data.data.player1.highRun}}</div>
								<div
									style="text-align: center;"
									class="tsls_meta"
								>HOOGSTE SERIE</div>
							</div>
							<div
								style="display:inline-block;margin-left:1vw;"
								v-if="data.data.player1.info.liveRun"
							>
								<div
									style="text-align: center;"
									class="tsls_highrun color2"
								>{{data.data.player1.info.liveRun}}</div>
								<div
									style="text-align: center;"
									class="tsls_meta"
								>ACTUELE SERIE</div>
							</div>
						</div>
					</td>
					<td style="">

						<div
							style="text-align: center;"
							class="tsls_innings"
						></div>
					</td>
					<td style="text-align:center;">
						<div>

							<div
								style="display:inline-block;margin-right:1vw;"
								v-if="data.data.player2.info.liveRun"
							>
								<div
									style="text-align: center;"
									class="tsls_highrun color1"
								>{{data.data.player2.info.liveRun}}</div>
								<div
									style="text-align: center;"
									class="tsls_meta"
								>ACTUELE SERIE</div>
							</div>
							<div style="display:inline-block;">
								<div
									style="text-align: center;"
									class="tsls_highrun color2"
								>{{data.data.player2.highRun}}</div>
								<div
									style="text-align: center;"
									class="tsls_meta"
								>HOOGSTE SERIE</div>
							</div>
						</div>
					</td>
				</tr>
				<tr style="">
					<td>
						<div
							style="text-align: center;"
							class="tsls_average color1"
						>{{data.data.player1.average}}</div>
						<div
							style="text-align: center;"
							class="tsls_meta"
						>MOY</div>
					</td>
					<td style="">
						<div
							style="text-align: center;"
							class="tsls_innings"
						></div>

					</td>
					<td>
						<div
							style="text-align: center;"
							class="tsls_average color2"
						>{{data.data.player2.average}}</div>
						<div
							style="text-align: center;"
							class="tsls_meta"
						>MOY</div>
					</td>
				</tr>
			</table>
			<div v-show="false"> {{data}}
			</div>
		</div>
	</div>

</template>


<script>
import axios from "axios";

export default {
	components: {
		axios,
	},
	data() {
		return {
			directus_url: "https://targascore-directus.targaserver2.nl",
			data: null,
		};
	},
	props: {},
	computed: {
		scoreboard_id() {
			//return document
			//.getElementsByTagName("vue-widget")[0]
			//.getAttribute("product_id");
			const params = new Proxy(
				new URLSearchParams(window.location.search),
				{
					get: (searchParams, prop) => searchParams.get(prop),
				}
			);
			// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
			let value = params.scoreboard; // "some_value"
			return value;
		},
		isFullScreen() {
			//return document
			//.getElementsByTagName("vue-widget")[0]
			//.getAttribute("product_id");
			const params = new Proxy(
				new URLSearchParams(window.location.search),
				{
					get: (searchParams, prop) => searchParams.get(prop),
				}
			);
			// Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
			let value = params.fs; // "some_value"
			return value ? true : false;
		},
		scoreboardIsActive() {
			let self = this;
			if (self.data && self.data.date_updated) {
				if (
					self
						.$date()
						.diff(self.$date(self.data.date_updated), "hours") < 5
				) {
					return true;
				}
			}
			return false;
		},
	},
	mounted() {
		let self = this;

		self.tsls_reloadData(self.scoreboard_id);
		window.setInterval(() => {
			self.tsls_reloadData(self.scoreboard_id);
		}, 6000);
	},
	methods: {
		tsls_reloadData(scoreboard_id) {
			let self = this;
			axios
				.get(self.directus_url + "/items/data/", {
					params: {
						filter: {
							_and: [
								{
									scoreboard: {
										_eq: scoreboard_id,
									},
								},
							],
						},
						fields: ["*"],
					},
				})
				.then((response) => {
					if (
						response &&
						response.data &&
						response.data.data &&
						response.data.data.length > 0
					) {
						self.data = response.data.data[0];
					}
				});
		},
		tsls_openFullScreen() {
			if (!this.isFullScreen) {
				window.open(location.href + "&fs=1");
			}
		},
	},
};
</script>


<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700&display=swap");

body {
	padding: 0;
	margin: 0px;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.tsls_outer {
	font-family: "Baloo 2", "sans-serif";
	display: block;

	table,
	table tr td {
		margin: 0px;
		border: 0;
	}
	table {
		width: 100% !important;
	}
	table tr td {
		padding: 0 20px 0 20px !important;
	}

	.color1 {
		color: #ffffff;
	}
	.color2 {
		color: #ffcc02;
	}

	.tsls_scoreboard_name {
		font-size: 3vw;
		font-weight: 700;
		padding-top: 1vh;
	}
	.tsls_discipline {
		font-size: 2vw;
		font-weight: 100;
	}
	.tsls_meta {
		font-size: 2vw;
		font-weight: 100;
		opacity: 0.5;
	}

	.tsls_player {
		font-size: 5vw;
		font-weight: 700;
		line-height: 1em;
	}

	.tsls_player span {
		font-size: 5vw;
		font-weight: 100;
	}

	.tsls_car {
		font-size: 10vw;
		font-weight: 700;
		line-height: 1em;
	}

	.tsls_car span {
		font-size: 10vw;
		font-weight: 100;
		line-height: 1em;
	}

	.tsls_innings {
		font-size: 8vw;
		font-weight: 400;
		line-height: 1em;
	}

	.tsls_average {
		font-size: 5vw;
		font-weight: 400;
		line-height: 1em;
		padding-top: 1vw;
	}
	.tsls_highrun {
		font-size: 5vw;
		font-weight: 400;
		line-height: 1em;
	}

	.tsls_average {
		font-size: 5vw;
		font-weight: 400;
		line-height: 1em;
	}
}
</style>
