import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";

import vueCustomElement from "vue-custom-element";
Vue.use(vueCustomElement);

import VueDayjs from "vue-dayjs-plugin";

Vue.use(VueDayjs);

App.store = store;
App.router = router;

Vue.customElement("targascore-livescore", App);
