<template>
	<div>
		HOME
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: "HomeView",
	mounted() {},
};
</script>
